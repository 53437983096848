html, body {
  font-family: 'Quicksand', sans-serif;
}

.home {
  background-image: url('/public/img/wrapper-1.svg');
  background-size: cover;
  height: 100vh;
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-header {
  margin-top: 0px;
  margin-bottom: 0;
  font-size: 4.5rem;
  font-weight: 400;
  line-height: 115%;
  font-family: 'Quicksand';
}

.type-header {
  margin-top: 0px;
  margin-bottom: 0;
  font-size: 4.5rem;
  font-weight: 400;
  line-height: 115%;
}

.home-header-span {
  color: #91e6ff;
}

.whatsapp-color {
  background: -webkit-linear-gradient(#7ceca5, #25D366, #009929);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Quicksand';
  font-weight: 600;
}

.facebook-color {
  background: -webkit-linear-gradient(#99c5ff, #65a8ff, #0077b5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Quicksand';
  font-weight: 600;
}

.instagram-color {
  font-family: 'Quicksand';
  font-weight: 600;
  background: -webkit-linear-gradient(#F58529,#FEDA77, #d8699b, #9968b5, #717be7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.type-header span {
  font-family: 'Quicksand'!important;
  color: #91e6ff;
}

.home-paragraph {
  margin-bottom: 40px;
  padding-top: 1rem;
  font-size: 1.1rem;
  font-weight: bold;
  font-family: 'Quicksand';
}

.work-with-us-button {
  font-family: 'Quicksand';
  font-weight: bold;
  color: black;
  background-color: #91e6ff;
  border-radius: 1.25rem;
  padding: 0.6rem 1rem;
  transition: background-color .3s cubic-bezier(.889, .013, .047, .995);
  text-decoration: none;
}

.work-with-us-button:hover {
  color: black;
  background-color: #5ec1df;
}

.learn-more-button {
  font-weight: bold;
  font-family: 'Quicksand';
  background-color: #333751;
  border-radius: 1.25rem;
  padding: 0.6rem 1rem;
  margin-left: 20px;
  border: 1px solid #4a5283;
  transition: background-color .3s cubic-bezier(.889, .013, .047, .995);
  text-decoration: none;
}

.learn-more-button:hover {
  box-shadow: none;
  color: white;
  background-color: #4a5283;
}

.home-wrapper {
  margin-bottom: 150px;
}

/* when screen is less than 768px */
@media (max-width: 768px) {
  .home-wrapper {
    text-align: center;
  }

  .home-header {
    font-size: 2.5rem;
  }

  .type-header {
    font-size: 2.5rem;
  }
}