body {
  background-color: #0f1117;
}

.container {
  padding-left: 11%;
  padding-right: 11%;
}

/* Container should be responsive */
@media (max-width: 768px) {
  .container {
    padding-left: 10%;
    padding-right: 10%;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p {
  font-size: 20px;
  line-height: 26px;
}

@font-face {
  font-family: 'PixelifySans';
  src: local('PixelifySans'), url('./PixelifySans.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #91e6ff #2d313e;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #2d313e;
}

*::-webkit-scrollbar-thumb {
  background-color: #91e6ff;
  border-radius: 10px;
  border: 3px solid #2d313e;
}